import styled from 'styled-components';
import {
	media,
	setFontFamily,
	setFontSize,
	setColors,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	text-align: center;
	max-width: 320px;
	margin-bottom: 30px;
	padding: 0 15px;
	p:nth-of-type(1) {
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.verySmall};
		line-height: 1.38;
		letter-spacing: -0.21px;
		font-weight: ${setFontWeight.bold};
		margin: 10px auto 10px auto;
	}
	span {
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.veryTiny};
		font-weight: ${setFontWeight.normal};
		line-height: 1.5;
		letter-spacing: -0.11px;
		color: ${setColors.lightBlack};
		margin-bottom: 10px;
		text-align: justify;
	}

	${media.tablet`
		p:nth-of-type(1) {
			font-size: ${setFontSize.tinySmall};
		}
	`}

	${media.desktop`
		p:nth-of-type(1) {
			font-size: ${setFontSize.smaller};
		}
		span{
			font-size: ${setFontSize.mediumTiny};
			margin: 10px auto 10px auto;
		}
	`}

	${media.large`
		margin: 0 10px;
		/* margin-bottom: 30px; */
		p:nth-of-type(1){
			font-size: ${setFontSize.mediumSmall};
		}
	`}
`;
