import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';
import BulletIcon from '../../../images/bullet_icon.png';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-top: 150px;
	.image-holder {
		display: grid;
		padding: 0 15px;
		order: ${props => (props.invert ? 0 : 1)};
		${mediaQuery.mobileXL`
			padding-top: 10px;
			padding-bottom: 15px;
            order: unset;
		`}
	}

	.content {
		padding: 0 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		h1,
		h2 {
			color: ${setColors.themeBlue};
			line-height: 1.09 !important;
			font-weight: ${setFontWeight.extraBold} !important;
			text-transform: none;
			letter-spacing: -0.69px !important;
			margin-top: 10px;
			margin-bottom: 10px;
			font-family: ${setFontFamily.bold};
			font-size: ${setFontSize.mediumLarge};
			${mediaQuery.laptopXL`
                font-size: ${setFontSize.veryLarge};
            `};
			${mediaQuery.tabletL`
                font-size: ${setFontSize.mediumSmall};
            `};
			width: 100%;
		}
		h1,
		h2,
		.subcontent,
		.link-holder {
			text-align: left;
			padding-right: ${props => (props.invert ? '0' : '40px')};
			padding-left: ${props => (props.invert ? '40px' : '0')};
			${mediaQuery.mobileXL`
                padding-right: 0;
                padding-left: 0;
		    `}
		}
		.subcontent {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					&:before {
						content: '';
						display: block;
						height: 23px;
						width: 18px;
						background-image: url('${BulletIcon}');
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						margin-right: 10px;
						${mediaQuery.tabletL`width: 14px`}
					}
					display: flex;
					margin: 0.85em 0;
					span {
						width: calc(100% - 28px);
					}
				}
			}
		}

		.subcontent,
		.link-holder {
			p {
				margin: 0;
			}
			line-height: 1.5;
			font-family: ${setFontFamily.book};
			font-size: ${setFontSize.tiny};
			color: ${setColors.lightBlack};
			${mediaQuery.laptopXL`
                font-size: ${setFontSize.mediumTiny};
            `};
			${mediaQuery.tabletL`
                font-size: ${setFontSize.veryTiny};
            `};
			${mediaQuery.mobileXL`
                font-size: ${setFontSize.veryTiny};
            `};
		}

		.link-holder {
			margin: 0;
			padding-top: 0.85em;
			padding-bottom: 0.85em;
			a {
				font-family: ${setFontFamily.bold};
				color: ${setColors.themeBlue};
				text-decoration: none;
				color: ${setColors.themeBlue};
			}
			svg {
				height: 18px;
				width: 18px;
				margin-left: 5px;
				color: ${setColors.themeBlue};
				vertical-align: middle;
			}
		}
	}

	${mediaQuery.laptopXL`padding-top: 100px`}
	${mediaQuery.tabletML`grid-template-columns: ${props =>
		`${props.invert ? '1fr 1.766fr' : '1.766fr 1fr'}`};`};

	${mediaQuery.mobileXL`grid-template-columns: 1fr; padding-top: 70px`};
`;
