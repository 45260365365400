import React from 'react';
import * as S from './styles';

const Snippet = ({ url, alt, title, subtitle, key }) => {
	return (
		<S.Wrapper key={key || alt || title}>
			<img src={url} width="100" height="100" alt={alt || title} />
			<p>{title}</p>
			<span>{subtitle}</span>
		</S.Wrapper>
	);
};

export default Snippet;
