import styled from 'styled-components';
import { mediaQuery, setColors } from '../../../utils/helpers';

export const InfoBoxWrapper = styled.div`
	${mediaQuery.mobileM`
		padding-bottom: 40px;
	`}
`;

export const SectionTextBoxWrapper = styled.div`
	padding-top: 100px;
	${mediaQuery.mobileM`
		padding-top: 20px;
	`}
`;

export const CustomerStoryWrapper = styled.div`
	${mediaQuery.tabletXS`
    	margin-top: 80px;
	`}
	${mediaQuery.mobileM`
		margin:0;
	`}
`;

export const ImgWrapper = styled.div`
	padding-top: 30px;
`;

export const SectionWrapper = styled.div`
	padding-top: 40px;
`;

export const FeatureSnippetWrapper = styled.div`
	padding-top: 70px;
	${mediaQuery.tabletXL`
		padding-top: 40px;
	`}
`;

export const InfoboxTwoWrapper = styled.div`
	h1,
	h2 {
		color: ${setColors.lightBlack} !important;
	}
`;

export const CTAWrapper = styled.div`
	padding-top: 130px;
	padding-bottom: 20px;
	${mediaQuery.mobileM`
		padding-top: 50px;
	`}
`;
