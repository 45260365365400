import styled from 'styled-components';
import {
	media,
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Section = styled.section`
	display: grid;
	justify-items: center;
	padding-top: 60px;
`;

export const Wrapper = styled.div`
	width: 100%;
`;

export const ImageWrapper = styled.div`
	width: 100%;
	padding: 30px 0 0 0;
`;
export const StatsWrapper = styled.div`
	width: 100%;
	margin-top: 20px;
	${media.tablet`
		margin-top: 60px;
	`}
	${media.large`
		margin-top: 100px;
	`}
`;

export const ImageHolder = styled.div`
	display: grid;
	justify-items: center;
	align-items: start;
	margin: 0 -15px;
	margin-bottom: 20px;
	${media.small`grid-template-columns: 1fr;`};
	${media.desktop`
		grid-template-columns: 1fr 1fr 1fr;
		row-gap: 50px;
	`};
	${media.large`
		row-gap: 60px;
	`}
`;

export const FeatureWrapper = styled.div`
	margin-bottom: 70px;
	margin-top: 10px;
	${media.tablet`
		margin-top: 100px;
		margin-bottom: 110px;
	`}
	${media.large`
		margin-bottom: 140px;
	`}
`;

export const LinkWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${setColors.themeBlue};
	text-decoration: none;
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.extraBold};
	font-family: ${setFontFamily.black};

	a {
		margin-right: 10px;
		text-decoration: none;
	}

	span {
		color: ${setColors.themeBlue};
	}

	a:visited {
		color: ${setColors.themeBlue};
	}
`;

export const CardSliderWrapper = styled.div`
	display: none;

	.slick-dots li button:before {
		font-size: ${setFontSize.smallest};
	}

	.slick-dots li button:before {
		color: #bedbfc;
	}

	.slick-dots li.slick-active button:before {
		color: ${setColors.themeBlue};
		opacity: 1;
	}

	.slick-list {
		margin: 0 auto;
	}

	${mediaQuery.tabletL`
		padding: 30px 0;
		display: block;
		justify-content: center;
		align-items: center;

		.slick-dots{
			bottom: -45px;
		}

		.imageCardContainer{
			overflow: unset;
		}

		.imageCardWrapper{
			max-width: 100%;
			display: flex !important;
		}

		.slick-list {
			max-width: 540px;
		}

		.slick-arrow{
			display: none !important;
		}
	`}

	${mediaQuery.tabletXS`
		.slick-list {
			max-width: 520px;
		}
	`}

	${mediaQuery.mobile700`
		.slick-list {
			max-width: 420px;
		}
	`}

	${mediaQuery.mobileL`
		.slick-list {
			border-radius: 15px;
			max-width: 370px;
		}
	`}

	${mediaQuery.mobileM`
		.slick-list {
			max-width: 360px;
			max-height: 430px;
		}
	`}

	${mediaQuery.mobile430`
		.slick-list {
			max-width: 300px;
			max-height: 400px;
		}
	`}

	${mediaQuery.mobileS`
		.slick-list{
			max-width: 280px;
			max-height: 400px;
		}
	`}
`;

export const CardWrapper = styled.div`
	padding: 30px 0 10px 0;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-auto-flow: row;
	gap: 30px;

	${mediaQuery.tabletL`
		display: none
	`}

	.imageCardWrapper {
		grid-column: span 3;
		max-height: 500px;
	}

	.imageCardWrapper:nth-child(n + 3) {
		grid-column: span 2;
		max-height: 420px;

		.imageContainer {
			margin-top: 20px;
		}
	}
`;

export const CustomerTestimonialWrapper = styled.div`
	padding: 30px 0 100px 0;

	.slick-dots {
		bottom: -45px;
	}

	.slick-dots li button:before {
		font-size: ${setFontSize.smallest};
	}

	.slick-dots li button:before {
		color: #bedbfc;
	}

	.slick-dots li.slick-active button:before {
		color: ${setColors.themeBlue};
		opacity: 1;
	}

	${mediaQuery.laptopL`
		blockquote{
			font-size: ${setFontSize.mediumSmall}
		}
	`}
	.slick-arrow {
		display: none !important;
	}
	${mediaQuery.laptopM`
		blockquote{
			font-size: ${setFontSize.smaller}
		}
		.slick-list {
			border-radius: 15px;
			max-width: 936px;
		}
		#description-container{
			padding: 40px 20px;
		}
	`}
	${mediaQuery.laptopS`
		blockquote{
			font-size: ${setFontSize.tinySmall}
		}
	`}
	${mediaQuery.tablet932`
		display: flex;
		align-items: center;
		justify-content: center; 
		blockquote{
			font-size: ${setFontSize.smaller}
		}
		#customer-story-wrapper{
			flex-direction: column;
		}
		#customer-img-wrapper{
			width: 100%;
			max-width: unset;
			img {
				border-radius: 10px 10px 0 0 !important;
			}
		}
		.slick-list {
			border-radius: 15px;
			max-width: 700px;
		}
		#description-container{
			width: 100%;
			border-radius: 0 0 10px 10px;
		}
	`}

	${mediaQuery.tabletM`
		.slick-list {
			border-radius: 15px;
			max-width: 620px;
		}
	`}


	${mediaQuery.tabletXS`
		.slick-list {
			border-radius: 15px;
			max-width: 520px;
		}
		blockquote{
			font-size: ${setFontSize.verySmall}
		}
		.client{
			font-size: ${setFontSize.verySmall}
		}
	`}

	${mediaQuery.mobile700`
		.slick-list {
			border-radius: 15px;
			max-width: 420px;
		}
	`}

	${mediaQuery.mobileL`
		.slick-list {
			border-radius: 15px;
			max-width: 370px;
		}
	`}

	${mediaQuery.mobileM`
		.slick-list {
			border-radius: 15px;
			max-width: 325px;
		}
	`}

	${mediaQuery.mobile430`
		.slick-list {
			border-radius: 15px;
			max-width: 300px;
		}
	`}

	${mediaQuery.mobileS`
		.slick-list {
			border-radius: 15px;
			max-width: 275px;
		}
	`}
`;

export const CardSlider = styled.div`
	.slick-list {
		border-radius: 15px;
		max-height: 100%;
		max-width: 645px;
	}
`;

export const UsecasesWrapper = styled.div`
	position: relative;
	margin-top: 10px;
	text-align: center;
	padding: 60px 0;
	flex-direction: column;
`;

export const TitleWrapper = styled.div`
	margin-top: 60px;
	${mediaQuery.tabletXS`
		svg {
			height: 25px;
			width: 300px;
		}
	`}
	${mediaQuery.mobileM`
		svg {
			height: 25px;
			width: 250px;
		}
	`}
	${mediaQuery.mobileXS`
		text-align: right;
		padding: 0 15px; 
		svg {
			height: 25px;
			width: 200px;
		}
	`}
`;

export const ArrowWrapper = styled.div`
	position: absolute;
	width: 140px;
	height: 140px;
	transform: scaleX(-1) rotate(-28deg);
	top: 28px;
	left: 36%;

	${mediaQuery.desktopS`
		left: 35%;
	`}

	${mediaQuery.desktopXS`
		left: 34%;
	`}
	${mediaQuery.laptopXL`
		left: 33%;
	`}
	${mediaQuery.laptopL`
		left: 32%;
	`}
	${mediaQuery.laptopM`
		left: 30.5%;
	`}
	${mediaQuery.laptopSM`
		left: 29.5%;
	`}
	${mediaQuery.laptopS`
		left: 28.5%;
	`}
	${mediaQuery.tabletL`
		left: 26%;
	`}
	${mediaQuery.tablet932`
		left: 25%;
	`}
	${mediaQuery.tabletM`
		left: 23%;
	`}
	${mediaQuery.tabletS`
		left: 21%;
	`}
	${mediaQuery.tabletXS`
		left: 24%;
	`}

	${mediaQuery.mobile700`
		left: 19.5%;
	`}
	${mediaQuery.mobileL`
		left: 16.5%;
	`}

	@media (max-width: 550px) {
		left: 14%;
	}

	${mediaQuery.mobileM`
		left: 14%;

		svg{
			height: 75px
		}
	`}
	@media (max-width: 470px) {
		left: 11%;
	}
	${mediaQuery.mobile430`
		left: 9%;
	`}

	${mediaQuery.mobileS`
		left: 4%;

		svg{
			height: 60px;
		}
	`}

	${mediaQuery.mobileXS`
		left: 26%;
		top: 4%;

		svg{
			height: 60px
		}
	`}

	@media (max-width: 360px) {
		left: 24%;
		top: 3.5%;
	}

	@media (max-width: 360px) {
		left: 21%;
		top: 3.5%;
	}

	@media (max-width: 340px) {
		left: 19%;
		top: 3.5%;
	}

	${mediaQuery.mobileXXS`
		left: 14%;
		top: 3.5%;

		svg{
			height: 60px
		}
	`}
`;

export const UseCaseSliderWrapper = styled.div`
	.sliderClass {
		overflow: hidden;
	}
`;

export const LocalWrapper = styled.div`
	margin-top: 60px;
`;
