import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { IconNext } from '../icons/index';
import { checkLink, setColors } from '../../../../utils/helpers';
import * as S from './styles';

const Customers = ({
	section2Title,
	section2Images,
	subtitle,
	sectionIcon,
	customBgColor,
	cta,
	ctaLink,
	imgStyle,
	style,
}) => {
	const generateLink = (link, linkText) => {
		const linkType = checkLink(link);
		if (linkType === 2) {
			return (
				<Link to={`${link}/`}>
					{linkText} <IconNext />
				</Link>
			);
		} else if (linkType === 1) {
			return (
				<a href={link} target="_self">
					{linkText} <IconNext />
				</a>
			);
		} else {
			return (
				<a href={link} target="_blank" rel="noopener noreferrer">
					{linkText} <IconNext />
				</a>
			);
		}
	};

	return (
		<S.Wrapper customBgColor={customBgColor}>
			<S.Container
				style={{ marginTop: '50px', padding: '0', paddingTop: '32px' }}
			>
				{sectionIcon && (
					<div style={{ width: '100%', textAlign: 'center' }}>
						<img src={sectionIcon.file.url} alt={sectionIcon.title} />
					</div>
				)}
				<S.CustomerCenterHeader>{section2Title}</S.CustomerCenterHeader>
				<S.TextWrapper>{subtitle && <p>{subtitle}</p>}</S.TextWrapper>
				<S.LogoHolder>
					{section2Images.map(image => (
						<S.ImageWrapper key={image.title}>
							<span>
								<Img
									fluid={image.fluid}
									style={{ width: '100%', ...style }}
									imgStyle={imgStyle}
									alt={image.title}
								/>
							</span>
						</S.ImageWrapper>
					))}
				</S.LogoHolder>
				{cta && generateLink(ctaLink, cta)}
			</S.Container>
		</S.Wrapper>
	);
};

Customers.defaultProps = {
	customBgColor: setColors.warmGray,
	imgStyle: {},
	style: {},
};

export default Customers;
