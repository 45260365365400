import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { IconNext } from '../../global/icons/index';
import { checkLink } from '../../../../utils/helpers';
import * as S from './style';

const renderContent = subtitle => {
	if (subtitle.type === 'text') {
		return `<p>${subtitle.content[0]}</p>`;
	} else if (subtitle.type === 'ul') {
		let htmlText = '';
		subtitle.content.forEach(node => {
			if (!node.startsWith('{')) {
				htmlText += node;
			} else {
				htmlText += '<ul>';
				const list = node.substring(1, node.length - 1).split(';');
				list.forEach(
					listItem => (htmlText += `<li><span>${listItem}<span></li>`),
				);
				htmlText += '</ul>';
			}
		});
		return htmlText;
	} else {
		return 'Unsupported answer type';
	}
};

const generateLink = (link, linkText) => {
	const linkType = checkLink(link);
	if (linkType === 2) {
		return (
			<p className="link-holder">
				<Link to={`${link}/`}>
					{linkText} <IconNext />
				</Link>
			</p>
		);
	} else if (linkType === 1) {
		return (
			<p className="link-holder">
				<a href={link} target="_self">
					{linkText} <IconNext />
				</a>
			</p>
		);
	} else {
		return (
			<p className="link-holder">
				<a href={link} target="_blank" rel="noopener noreferrer">
					{linkText} <IconNext />
				</a>
			</p>
		);
	}
};

const Infobox = ({ image, invert, title, content, isHeroHeader }) => {
	return (
		<S.Wrapper invert={invert}>
			<div className="image-holder">
				<Img
					fluid={image.fluid}
					alt={image.title}
					imgStyle={{
						objectFit: 'fill',
						borderRadius: '10px',
					}}
				/>
			</div>
			<div className="content">
				{isHeroHeader === true ? <h1>{title}</h1> : <h2>{title}</h2>}
				<div
					className="subcontent"
					dangerouslySetInnerHTML={{ __html: renderContent(content.subtitle) }}
				></div>
				{content.link.linkURL &&
					content.link.linkText &&
					generateLink(content.link.linkURL, content.link.linkText)}
			</div>
		</S.Wrapper>
	);
};

Infobox.defaultProps = {
	image: {},
	invert: false,
	content: {
		subtitle: {
			type: 'text',
			content: [''],
		},
		link: {
			linkURL: '',
			linkText: '',
		},
	},
};

Infobox.propTypes = {
	image: PropTypes.object,
	invert: PropTypes.bool,
	title: PropTypes.string,
	content: PropTypes.object,
};

export default Infobox;
