import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	SetContainerWidth,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	background-color: ${props => props.customBgColor};
	a {
		margin-bottom: 0.85em;
		text-decoration: none;
		color: ${setColors.themeBlue};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: ${setFontSize.verySmall};
		svg {
			margin-left: 5px;
		}
	}
	${mediaQuery.laptopXL`
		a{
			font-size: ${setFontSize.mediumTiny};
			svg{
				width: 16px;
				height: 16px;
			}
		}
	`}
`;

export const Container = styled.div`
	max-width: ${SetContainerWidth.maxWidth};
	width: ${SetContainerWidth.fullWidth};
	margin: ${props => (props.margin ? `${props.margin}px` : 0)} auto;
	${mediaQuery.laptopXL`width: ${SetContainerWidth.laptopXL};`};
	${mediaQuery.tabletL`width: ${SetContainerWidth.tabletL};`};
	${mediaQuery.mobileXL`width: ${SetContainerWidth.mobileXL} !important; max-width: ${SetContainerWidth.fullWidth} !important;margin: 0 auto !important; display: block`};
	${mediaQuery.mobileM`width: ${SetContainerWidth.tabletL} !important;`};
	${mediaQuery.mobileS`width: ${SetContainerWidth.mobileS} !important;`};
	${props =>
		props.isSubHeaderText
			? `padding-bottom: 80px;padding-top: 40px;display: flex;`
			: ``}
	display: grid;
	justify-items: center;
`;

export const TextWrapper = styled.div`
	p {
		text-align: center;
		margin: 0.85em auto;
		max-width: 800px;
		font-size: ${setFontSize.verySmall};
		line-height: 1.3;
		font-weight: ${setFontWeight.normal};
		letter-spacing: normal;
		box-sizing: border-box;
	}
	${mediaQuery.laptopXL`
		p{
			font-size: ${setFontSize.mediumTiny};
		}
	`}
	${mediaQuery.mobileXL`
			padding: 0 15px;
	`}
`;

export const CenterHeader = styled.h3`
	margin: ${props => (props.about ? '0' : '0 auto 30px auto')};
	text-align: center !important;
	line-height: ${props =>
		props.lineHeight ? props.lineHeight : 'normal'} !important;
	color: ${setColors.lightBlack};
	font-family: ${setFontFamily.black};
	font-size: ${setFontSize.mediumLarge};
	font-weight: ${setFontWeight.extraBold};
	letter-spacing: ${props =>
		props.letterSpacing ? props.letterSpacing : 'normal'} !important;
	${mediaQuery.laptopXL`font-size: ${setFontSize.veryLarge};`}
	${mediaQuery.tabletL`font-size: ${setFontSize.mediumSmall}; text-align: center;`}
	${mediaQuery.mobileXL`
		font-size: ${props => (props.about ? setFontSize.large : '')};
	`}
`;

export const CustomerCenterHeader = styled.h3`
	margin: 0 auto 15px auto;
	text-align: center !important;
	line-height: ${props =>
		props.lineHeight ? props.lineHeight : 'normal'} !important;
	color: ${setColors.lightBlack};
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.mediumSmall};
	font-weight: ${setFontWeight.bold};
	line-height: 1.92;
	letter-spacing: 0.21px;
`;

export const LogoHolder = styled.div`
	display: grid;
	width: ${SetContainerWidth.fullWidth};
	grid-template-columns: repeat(6, 144px);
	justify-content: center;
	padding-bottom: 32px;

	${mediaQuery.tabletML`grid-template-columns: repeat(2, 1fr);`}
`;

export const ImageWrapper = styled.div`
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 90px;
		width: 142px;
		margin: 0 auto;
		${mediaQuery.mobileXL`width: 125px;`}
		.gatsby-image-wrapper {
			height: 90px;
			width: 142px;
			img {
				object-fit: contain !important;
			}
		}
	}
`;
