import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/Layout';
import SEO from '../components/global/seo';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoBox from '../components/global/info-box/InfoBox';
import Customers from '../components/global/customers/Customers';
import Snippet from '../components/global/snippet/Snippet';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import InfoboxTwo from '../components/solution-page-template/Infobox';
import CTA from '../components/global/CTA/CTA';
import { ImageHolder } from '../components/home-page/about-chatbots/styles';
import * as S from '../components/live-chat-tool/styles';

const LiveChatTool = ({ data }) => {
	const {
		section1Title,
		section1Subtitle,
		section1ButtonText,
		section1ButtonLink,
		section1Image,
		section2Title,
		section2Images,
		section3Title,
		section3Subtitle,
		section3Image,
		section4Images,
		section5Title,
		section5Image,
		section5Content,
		section6Title,
		section6Image,
		section6Content,
		section7Title,
		section7Image,
		section7Content,
		section8Title,
		section8Image,
		section8Content,
		section9Title,
		section9Image,
		section9Content,
		section10Title,
		section10Subtitle,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.contentfulLiveChat;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<S.InfoBoxWrapper>
					<InfoBox
						image={section1Image}
						title={section1Title}
						subtitle={section1Subtitle}
						buttonText={section1ButtonText}
						buttonLink={section1ButtonLink}
						hideImage="true"
						justifyContent="center"
						isHeroHeader
					/>
				</S.InfoBoxWrapper>
			</WrapperOne>
			<S.CustomerStoryWrapper>
				<Customers
					section2Images={section2Images}
					section2Title={section2Title}
				/>
			</S.CustomerStoryWrapper>
			<WrapperOne>
				<S.SectionTextBoxWrapper>
					<SectionTextBox
						title={section3Title}
						subtitle={section3Subtitle}
						largeHeading="true"
					/>
				</S.SectionTextBoxWrapper>
				<S.ImgWrapper>
					<Img
						fluid={section3Image.fluid}
						alt={section3Image.title}
						style={{ maxWidth: '1000px', margin: '0 auto' }}
					/>
				</S.ImgWrapper>

				<S.SectionWrapper>
					<ImageHolder>
						{section4Images.map(info => (
							<Snippet
								url={info.file.url}
								title={info.title}
								subtitle={info.description}
							/>
						))}
					</ImageHolder>
				</S.SectionWrapper>

				<S.FeatureSnippetWrapper>
					<S.InfoboxTwoWrapper>
						<InfoboxTwo
							image={section5Image}
							title={section5Title}
							invert={true}
							content={section5Content}
						/>
					</S.InfoboxTwoWrapper>
				</S.FeatureSnippetWrapper>
				<S.InfoboxTwoWrapper>
					<InfoboxTwo
						image={section6Image}
						title={section6Title}
						invert={false}
						content={section6Content}
					/>
				</S.InfoboxTwoWrapper>
				<S.InfoboxTwoWrapper>
					<InfoboxTwo
						image={section7Image}
						title={section7Title}
						invert={true}
						content={section7Content}
					/>
				</S.InfoboxTwoWrapper>
				<S.InfoboxTwoWrapper>
					<InfoboxTwo
						image={section8Image}
						title={section8Title}
						invert={false}
						content={section8Content}
					/>
				</S.InfoboxTwoWrapper>
				<S.InfoboxTwoWrapper>
					<InfoboxTwo
						image={section9Image}
						title={section9Title}
						invert={true}
						content={section9Content}
					/>
				</S.InfoboxTwoWrapper>
			</WrapperOne>
			<WrapperOne disableBottomMargin="true">
				<S.CTAWrapper>
					<CTA title={section10Title} subtitle={section10Subtitle} />
				</S.CTAWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	query {
		contentfulLiveChat {
			section1Title
			section1Subtitle
			section1ButtonText
			section1ButtonLink
			section1Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section2Title
			section2Images {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section3Title
			section3Subtitle
			section3Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section4Images {
				file {
					url
				}
				title
				description
			}
			section5Title
			section5Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section5Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section6Title
			section6Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section6Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section7Title
			section7Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section7Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section8Title
			section8Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section8Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section9Title
			section9Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section9Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section10Title
			section10Subtitle
			seoTitle
			seoDescription
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoArticle
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;

export default LiveChatTool;
